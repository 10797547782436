<script>
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import Question from '/~/extensions/health/components/assessment/questions/question-entry.vue'
import Tags from '/~/extensions/health/components/assessment/tags.vue'
import { useAssessment } from '/~/extensions/health/composables'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'health-assessment',
  components: {
    'tags-component': Tags,
    Question,
    BaseButton,
    BaseIcon,
  },
  setup() {
    const {
      sections,
      activeSectionIdx,
      activeQuestionIdx,
      toPrevQuestion,
      toNextQuestion,
      syncUserAnswers,
    } = useAssessment()

    return {
      sections,
      activeSectionIdx,
      activeQuestionIdx,
      toPrevQuestion,
      toNextQuestion,
      syncUserAnswers,
      ui,
    }
  },
  data() {
    return {
      isTagsSelectorOpen: false,
    }
  },
  computed: {
    activeSection() {
      return this.sections[this.activeSectionIdx]
    },
    activeQuestion() {
      return this.activeSection.questions[this.activeQuestionIdx]
    },
  },
  mounted() {
    window.addEventListener('keyup', this.handleKeys)
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.handleKeys)
    this.$parent.$emit('hidden')
  },
  methods: {
    handleKeys(event) {
      if (event.keyCode === 27) {
        modal.hide()
      }
    },
    onClose() {
      modal.hide()
    },
    onFinish() {
      this.onClose()
      this.$emit('finished')
    },
    onReady() {
      this.$parent.$emit('shown', this)
    },
    async onNextQuestion(value) {
      const status = await this.toNextQuestion(value)

      this.syncUserAnswers()

      if (status === 'done') {
        this.isTagsSelectorOpen = true
      }
    },
  },
}
</script>

<template>
  <transition
    enter-class="translate-y-2.5 opacity-0"
    leave-to-class="translate-y-2.5 opacity-0"
    :duration="100"
    appear
    @after-enter="onReady"
  >
    <template v-if="isTagsSelectorOpen">
      <div
        class="fixed flex h-full w-full justify-center overflow-auto bg-white"
      >
        <tags-component @confirm="onFinish" />
      </div>
    </template>

    <template v-else>
      <div
        class="fixed flex h-full w-full flex-col overflow-auto bg-white sm:flex-row"
      >
        <!-- Mobile Header -->
        <div v-if="ui.mobile" class="rounded-b-3xl bg-primary p-5">
          <div class="mb-6 flex items-center justify-between text-white">
            <div class="flex items-center">
              <div
                class="mr-2.5 flex h-[35px] w-[35px] items-center justify-center rounded-full bg-fg-warning"
              >
                <base-icon :svg="activeSection.icon" size="lg" />
              </div>

              <b>{{ activeSection.name }}</b>
            </div>

            <base-button size="sm" icon="rec/close" @click="onClose" />
          </div>

          <div class="-mx-[5px] flex justify-between">
            <div
              v-for="idx in activeSection.questions.length"
              :key="idx"
              class="mx-[5px] h-2.5 flex-1 rounded-full border border-white"
              :class="{
                'opacity-50':
                  idx < activeQuestionIdx + 1 || idx > activeQuestionIdx + 1,
                'bg-white': idx <= activeQuestionIdx + 1,
              }"
            />
          </div>

          <base-button
            class="my-[30px]"
            color="white"
            look="link"
            @click="toPrevQuestion"
          >
            <base-icon
              svg="plain/chevron-left"
              size="md"
              class="mr-[15px] text-xs"
            />
            Previous question
          </base-button>

          <div class="text-xl font-black text-white">
            {{ activeQuestion.title }}
            <span
              v-show="activeQuestion.content.type === 'numeric'"
              class="flex items-end text-sm"
            >
              <base-icon svg="health/measurements" size="md" class="mr-[5px]" />

              Use your keyboard to type your answer
            </span>
          </div>
        </div>

        <!-- Desktop Sidebar -->
        <div
          v-else
          class="flex flex-1 flex-col justify-between bg-primary p-10"
          :style="ui.desktop && 'min-height: 50rem'"
        >
          <div class="flex justify-between">
            <div
              v-for="(section, idx) in sections"
              :key="idx"
              class="flex flex-col items-center"
              :class="idx !== activeSectionIdx && 'opacity-50'"
            >
              <div
                class="mb-2.5 flex h-11 w-11 items-center justify-center rounded-full bg-white"
                :class="
                  idx === activeSectionIdx
                    ? 'bg-fg-warning text-white'
                    : 'bg-white text-primary'
                "
              >
                <base-icon :svg="section.icon" size="lg" />
              </div>

              <b class="text-sm text-white">
                {{ section.name }}
              </b>
            </div>
          </div>

          <div>
            <div class="pl-16">
              <base-button color="white" look="link" @click="toPrevQuestion">
                <base-icon
                  svg="plain/chevron-left"
                  size="md"
                  class="mr-[15px] text-xs"
                />
                Previous question
              </base-button>
            </div>

            <div class="flex">
              <div class="w-16 shrink-0">
                <div
                  v-for="idx in activeSection.questions.length"
                  :key="idx"
                  class="my-5 h-5 w-5 rounded-full border-2 border-white"
                  :class="{
                    'opacity-50': idx < activeQuestionIdx + 1,
                    'bg-white': idx <= activeQuestionIdx + 1,
                  }"
                />
              </div>

              <div class="max-w-md text-4xl font-black text-white">
                {{ activeQuestion.title }}
                <span
                  v-show="activeQuestion.content.type === 'numeric'"
                  class="flex items-end text-sm"
                >
                  <base-icon
                    svg="health/measurements"
                    size="md"
                    class="mr-[5px]"
                  />
                  Use your keyboard to type your answer
                </span>
              </div>
            </div>
          </div>

          <div class="pl-16">
            <base-button color="white" @click="onClose">
              Exit Health Assesment
            </base-button>
          </div>
        </div>

        <!-- Content -->
        <div
          class="relative flex flex-1 flex-col items-center justify-center p-10"
          :style="ui.desktop && 'min-height: 50rem'"
        >
          <div class="w-full overflow-y-auto">
            <div class="flex flex-col items-center justify-center">
              <base-icon
                v-if="!ui.mobile"
                class="text-fg-warning"
                :svg="activeSection.icon"
                :size="150"
              />
            </div>

            <question
              v-if="activeQuestion.content"
              :content="activeQuestion.content"
              class="flex grow flex-col items-center justify-center"
              @next="onNextQuestion"
            />
            <!-- <base-button @click="onNextQuestion">
              Next
            </base-button> -->
          </div>
        </div>
      </div>
    </template>
  </transition>
</template>
